<template>
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <div
        class="sidebar-shop mt-0"
        :class="{'show': mqShallShowLeftSidebar}"
      >

        <!-- Filters' Card -->
        <b-card-actions
          title="Фильтры"
          action-collapse
          :collapsed="collapsed"
          class="mb-1 mb-lg-0"
        >

          <!-- Price Slider -->
          <div class="price-slider">
            <h6 class="filter-title mt-0">
              Ценовой диапазон
            </h6>
            <vue-slider
              v-model="filters.priceRange"
              :min="sliderMin"
              :max="sliderMax"
              :lazy="true"
              :direction="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            />
          </div>

          <!-- Categories -->
          <div class="product-categories mt-2">
            <h6 class="filter-title">
              Категории
            </h6>
            <b-form-radio-group
              v-model="filters.categories"
              class="categories-radio-group"
              stacked
              :options="filterOptions.categories"
            />
          </div>

          <!-- Brands -->
          <!-- <div class="brands mt-2">
            <h6 class="filter-title">
              Brands
            </h6>
            <b-form-radio-group
              v-model="filters.brands"
              class="brands-radio-group"
              stacked
              :options="filterOptions.brands"
            />
          </div> -->

          <!-- Ratings -->
          <!-- <div class="ratings">
            <h6 class="filter-title">
              Ratings
            </h6>
            <div
              v-for="rating in filterOptions.ratings"
              :key="rating.rating"
              class="ratings-list"
            >
              <b-link>
                <div class="d-flex">
                  <feather-icon
                    v-for="star in 5"
                    :key="star"
                    icon="StarIcon"
                    size="17"
                    :class="[{'fill-current': star <= rating.rating}, star <= rating.rating ? 'text-warning' : 'text-muted']"
                  />
                  <span class="ml-25">&amp; up</span>
                </div>
              </b-link>
              <div class="stars-received">
                <span>{{ rating.count }}</span>
              </div>
            </div>
          </div> -->
        </b-card-actions>
      </div>
    </div>

    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="$emit('update:mq-shall-show-left-sidebar', false)"
    />
  </div>
</template>

<script>
import {
  BFormRadioGroup,
  // BLink,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BFormRadioGroup,
    // BLink,
    BCardActions,

    // 3rd Party
    VueSlider,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    sliderMin: {
      type: Number,
      required: false,
    },
    sliderMax: {
      type: Number,
      required: false,
    },
    filterOptions: {
      type: Object,
      required: true,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const collapsed = window.innerWidth < 991

    return {
      collapsed,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-slider.scss';
</style>

<style lang="scss" scoped>
.categories-radio-group,
.brands-radio-group,
.price-range-defined-radio-group {
    ::v-deep > .custom-control {
    margin-bottom: 0.75rem;
  }
}
</style>

import { ref } from '@vue/composition-api'
import store from '@/store'

export const useShopFiltersSortingAndPagination = () => {
  const filters = ref({
    q: '',
    priceRangeDefined: 'all',
    priceRange: [0, 100000],
    categories: 10000,
    brands: [],
    ratings: null,
    page: 1,
    perPage: 12,
  })

  const filterOptions = ref({
    categories: [],
    brands: [],
    ratings: [],
  })

  // Sorting
  const sortBy = ref({ text: 'Каталог', value: 'default' })
  const sortByOptions = [
    { text: 'Каталог', value: 'default' },
    { text: 'Новинки', value: 'featured' },
    { text: 'Цена ниже', value: 'price-asc' },
    { text: 'Цена выше', value: 'price-desc' },
  ]

  return {
    // Filter
    filters,
    filterOptions,

    // Sort
    sortBy,
    sortByOptions,
  }
}

export const useShopUi = () => {
  const itemView = 'grid-view'
  const itemViewOptions = [
    { icon: 'GridIcon', value: 'grid-view' },
    { icon: 'ListIcon', value: 'list-view' },
  ]

  // Pagination count <= required by pagination component
  const totalProducts = ref(null)

  return {
    itemView,
    itemViewOptions,
    totalProducts,
  }
}

export const useShopRemoteData = () => {
  const products = ref([])
  const shopMSG = (...args) => store.dispatch('app-ecommerce/shopMSG', ...args)
  const fetchProducts = (...args) => store.dispatch('app-ecommerce/fetchProducts', ...args)
  const fetchCategories = (...args) => store.dispatch('app-ecommerce/fetchCategories', ...args)
  const fetchPriceRange = (...args) => store.dispatch('app-ecommerce/fetchPriceRange', ...args)
  const fetchRelatedProducts = id => store.dispatch('app-ecommerce/fetchRelatedProducts', id)

  return {
    products,
    shopMSG,
    fetchProducts,
    fetchCategories,
    fetchPriceRange,
    fetchRelatedProducts,
  }
}
